import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import router from "@/__main__/router.mjs";
import FactionAccordion from "@/game-eft/components/FactionAccordion.jsx";
import HeadShotStats from "@/game-eft/components/HeadShotStats.jsx";
import MatchHistoryHeader from "@/game-eft/components/MatchHistoryHeader.jsx";
import MatchListRow from "@/game-eft/components/MatchListRow.jsx";
import ProfileStatsMaps from "@/game-eft/components/ProfileStatsMaps.jsx";
import ProfileStatsWeapons from "@/game-eft/components/ProfileStatsWeapons.jsx";
import {
  FactionBE,
  MATCHLIST_OFFSET,
} from "@/game-eft/constants/constants.mjs";
import type { Match } from "@/game-eft/models/graphql.mjs";
import { getFactionIcon } from "@/game-eft/utils/get-faction-icon.mjs";
import useFaction from "@/game-eft/utils/use-faction.mjs";
import useFactions from "@/game-eft/utils/use-factions.mjs";
import {
  debouncedUpdateRoute,
  MatchList,
  MatchTile,
} from "@/shared/Profile.jsx";
import { ProfileColumn, ProfileMatchlist } from "@/shared/Profile.style.jsx";
import ProfileRank from "@/shared/ProfileRank.jsx";
import { useEvalState } from "@/util/eval-state.mjs";
import { sanitizeNumber } from "@/util/helpers.mjs";
import { getLocaleString } from "@/util/i18n-helper.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

// Main: Component
export default function ProfileOverview() {
  const { t } = useTranslation();
  const state = useSnapshot(readState);
  const {
    parameters: [profileId],
  } = useRoute();
  // Raw state
  const $profile = state.eft.profiles[profileId];
  const $matchlist = state.eft.matchlists[profileId];
  const $matches = state.eft.matches;
  // Eval state
  const matchlist = useEvalState($matchlist);
  const profile = useEvalState($profile);
  // Constants
  const faction = useFaction(profileId);
  const playerFactionStats = useFactions(profileId);
  const playerFactionStat = playerFactionStats.find(
    (i) => i.faction === faction,
  );
  const FactionIcon = getFactionIcon(faction);
  const matchlistByFaction = (matchlist ?? []).filter(
    (i) => i.faction === faction,
  );
  const lastIdx = matchlistByFaction.length - 1;
  return (
    <>
      <ProfileColumn className="sidebar">
        <ProfileRank
          title={profile ? faction : FactionBE.SCAV}
          name={t("tft:levelValue", "Level {{level}}", {
            level: getLocaleString(sanitizeNumber(playerFactionStat?.level)),
          })}
          points={t("eft:stats.raids", "{{raids}} Raids", {
            raids: getLocaleString(sanitizeNumber(playerFactionStat?.matches)),
          })}
          below={undefined}
          belowTitle={undefined}
          winStreakBadgeComponent={undefined}
          color={undefined}
          image={undefined}
          imageComponent={React.createElement(FactionIcon ?? "div")}
          textBottomLeft={t("eft:stats.experience", "{{num}} experience", {
            num: getLocaleString(sanitizeNumber(playerFactionStat?.experience)),
          })}
        />
        <FactionAccordion profileId={profileId} />
        <Card padding={0}>
          <HeadShotStats profileId={profileId} />
        </Card>
        <ProfileStatsWeapons profileId={profileId} />
        <ProfileStatsMaps profileId={profileId} />
      </ProfileColumn>
      <ProfileColumn className="main">
        <MatchHistoryHeader profileId={profileId} />
        <ProfileMatchlist>
          <MatchList matchList={matchlist}>
            {matchlistByFaction.map((i, idx) => {
              const match = $matches[i.gameId];
              return (
                <React.Fragment key={i.gameId}>
                  <MatchTile id={i.gameId} match={match} height={126}>
                    {Boolean(!(match instanceof Error) && match) && (
                      <MatchListRow
                        profileId={profileId}
                        match={match as Match}
                      />
                    )}
                  </MatchTile>
                  {idx === lastIdx && <FetchMore />}
                </React.Fragment>
              );
            })}
          </MatchList>
        </ProfileMatchlist>
      </ProfileColumn>
    </>
  );
}

function FetchMore() {
  const [isMount, setMount] = useState(true);
  const ref = useCallback((node) => {
    if (node) {
      const observer = new IntersectionObserver((entries, obs) => {
        for (const entry of entries) {
          if (!entry.isIntersecting) continue;
          const {
            route: { currentPath, searchParams, state },
          } = router;
          if (typeof state.transient?.offset !== "number") {
            state.transient = {
              ...state.transient,
              offset: MATCHLIST_OFFSET,
            };
          }
          (state.transient.offset as number) += MATCHLIST_OFFSET;
          debouncedUpdateRoute(currentPath, searchParams, state);
          obs.disconnect();
          if (typeof node?.delete === "function") node.delete();
          setMount(false);
        }
      });
      observer.observe(node);
    }
  }, []);
  if (!isMount) return null;
  return (
    <div
      ref={ref}
      className="w-full invis"
      style={{ height: "var(--sp-px)" }}
    />
  );
}
